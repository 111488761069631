<template>
  <div>
    <cabecalho-pagina :titulo="$t('modulos.verificacao_intermediaria.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.verificacao_intermediaria.titulos.novo')" date-only
      :sem-botao-ok="!buscarPermissao('VerificacaoIntermediaria', 'Inserir')"
      :sem-filtro="!buscarPermissao('VerificacaoIntermediaria', 'Listar')" @abrirNovo="abrirNovo" @filtrar="filtrar">
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>

    <botao-padrao v-if="podeExcluir && buscarPermissao('VerificacaoIntermediaria', 'Deletar')
      " class="my-2" outlined color="danger" @click="excluirRegistros">
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>

    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="tabela.selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      :mostrar-acoes="true"
      filtros-com-colunas
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      :mostrar-checkbox="buscarPermissao('VerificacaoIntermediaria', 'Deletar')"
      @paginar="listarRegistros"
      @filtrarGlobal="listarRegistros"
      @filtroGlobal="(v) => (filtroAplicado.filter = v)"
      @nova-ordenacao="ordenacaoColuna"
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:acoes="{ slotProps }">
        <div class="d-flex">
          <dropdown-padrao text color="secondary">
            <template #botao>
              <v-icon> $dotsVertical </v-icon>
            </template>
            <dropdown-padrao-item v-if="buscarPermissao('VerificacaoIntermediaria', 'Editar')"
              @click="abrirEditar(slotProps.data)">
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
            <dropdown-padrao-item v-if="buscarPermissao('VerificacaoIntermediaria', 'Visualizar')"
              @click="abrirVisualizar(slotProps.data)">
              {{ $t('geral.botoes.visualizar') }}
            </dropdown-padrao-item>
            <dropdown-padrao-item v-if="slotProps.data.situacaoVerificacao == 'NaoValidado'"
              @click="abrirValidar(slotProps.data)">
              {{ $t('geral.botoes.validar') }}
            </dropdown-padrao-item>
            <dropdown-padrao-item v-if="buscarPermissao('VerificacaoIntermediaria', 'GerarPDF')"
              @click="gerarPDF(slotProps.data)">
              {{ $t('geral.botoes.gerar_pdf') }}
            </dropdown-padrao-item>
          </dropdown-padrao>
          <template>
            <div class="d-flex justify-center">
              <icone-padrao :icone="getSituacaoIcon(slotProps.data.situacaoVerificacao)"
                :tooltip="getSituacaoMessage(slotProps.data.situacaoVerificacao)" />
            </div>
          </template>
        </div>
      </template>
    </tabela-padrao-prime-vue>
    <modal-visualizacao-relatorio ref="modal-pdf" />
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import { VerificacaoIntermediariaService } from '@common/services';
import helpers from '@common/utils/helpers';
import Filtro from './components/filtro.vue';
import { ModalVisualizacaoRelatorio } from '@components/misc';
import { enumOperacaoSituacaoIcons } from '@enums/icons';

export default {
  components: {
    CabecalhoPagina,
    Filtro,
    ModalVisualizacaoRelatorio,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigoVerificacao',
            text: this.$t(
              'modulos.verificacao_intermediaria.tabela.codigo_verificacao'
            ),
            sortable: true,
          },
          {
            value: 'instrumento.codigoTag',
            text: this.$t(
              'modulos.verificacao_intermediaria.tabela.codigo_instrumento'
            ),
            sortable: true,
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t(
              'modulos.verificacao_intermediaria.tabela.tipo_instrumento'
            ),
            sortable: true,
          },
          {
            value: 'participanteCliente.nome',
            text: this.$t('modulos.verificacao_intermediaria.tabela.cliente'),
            sortable: true,
          },
          {
            value: 'dataVerificacao',
            text: this.$t(
              'modulos.verificacao_intermediaria.tabela.data_verificacao'
            ),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v);
            },
          },
          {
            value: 'situacaoVerificacao',
            text: this.$t(
              'modulos.verificacao_intermediaria.tabela.situacao_verificacao'
            ),
            sortable: true,
            formatter: (v) =>
              helpers.SituacaoVerificacaoEnum.find((el) => el.value == v)?.text,
          },
          {
            value: 'usuarioExecutante.nome',
            text: this.$t(
              'modulos.verificacao_intermediaria.tabela.usuario_executante'
            ),
            sortable: true,
          },
          {
            value: 'usuarioAvaliador.nome',
            text: this.$t(
              'modulos.verificacao_intermediaria.tabela.usuario_aprovador'
            ),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      colunaOrdenada: '',
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  created() {
    helpers.redirecionarSemPermissao(
      this,
      'VerificacaoIntermediaria',
      'Listar'
    );
    const filtros = helpers.buscarStateFiltros(this);
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.verificacao_intermediaria.titulos.listagem')
    );
    this.listarRegistros();
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros(
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.tabela.porPagina = porPagina;
      helpers.salvarStateFiltros(this, {
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
      });
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        sort: this.colunaOrdenada,
        ...this.filtroAplicado,
      };
      if (this.$route.params.id != undefined || this.$route.params.id != null)
        parametros.instrumentoId = this.$route.params.id;
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      VerificacaoIntermediariaService.listar(parametros)
        .then((res) => {
          this.tabela.selecionados = [];
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');

        VerificacaoIntermediariaService.excluir(
          this.tabela.selecionados.map((el) => el.id)
        )
          .then(() => {
            this.listarRegistros(
              this.tabela.paginaAtual,
              this.tabela.porPagina
            );
            this.toastSucesso(
              this.$t(`modulos.verificacao_intermediaria.exclusao_sucesso`)
            );
            this.tabela.selecionados = [];
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    retornarSituacao: function (situacao) {
      return helpers.SituacaoVerificacaoEnum.find((el) => el.value == situacao);
    },
    abrirNovo: function () {
      if (this.$route.params.id) {
        this.$router.push({
          name: 'verificacao-intermediaria-novo-instrumento',
          params: {
            instrumentoId: this.$route.params.id,
            codigo: this.$route.params.codigo,
          },
        });
      } else {
        this.$router.push({ name: 'verificacao-intermediaria-novo' });
      }
    },
    abrirEditar: function (item) {
      if (this.$route.params.id) {
        this.$router.push({
          name: 'verificacao-intermediaria-editar',
          params: {
            id: item.id,
            instrumentoId: this.$route.params.id,
            codigo: this.$route.params.codigo,
          },
        });
      } else {
        this.$router.push({
          name: 'verificacao-intermediaria-editar',
          params: { id: item.id },
        });
      }
    },
    abrirVisualizar: function (item) {
      if (this.$route.params.id) {
        this.$router.push({
          name: 'verificacao-intermediaria-visualizar',
          params: {
            id: item.id,
            instrumentoId: this.$route.params.id,
            codigo: this.$route.params.codigo,
          },
          query: {
            retorno: 'verificacao-intermediaria'
          },
        });
      } else {
        this.$router.push({
          name: 'verificacao-intermediaria-visualizar',
          params: { id: item.id },
          query: {
            retorno: 'verificacao-intermediaria'
          },
        });
      }
    },
    abrirValidar: function (item) {
      if (this.$route.params.id) {
        this.$router.push({
          name: 'verificacao-intermediaria-validar',
          params: {
            id: item.id,
            instrumentoId: this.$route.params.id,
            codigo: this.$route.params.codigo,
          },
        });
      } else {
        this.$router.push({
          name: 'verificacao-intermediaria-validar',
          params: { id: item.id },
        });
      }
    },
    gerarPDF: function (item) {
      this.$refs['modal-pdf'].abrirModal('verificacao-intermediaria', [
        { simbolo: 'id', valor: item.id },
      ]);
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
    getSituacaoIcon(situacao) {
      if (situacao) {
        const icon = enumOperacaoSituacaoIcons.find(
          (el) => el.value === situacao
        ).icon;

        return this.$vuetify.icons.values[icon];
      }
    },
    getSituacaoMessage(situacao) {
      if (situacao) {
        return enumOperacaoSituacaoIcons.find((el) => el.value === situacao)
          .message;
      }
    },
  },
};
</script>
