<template>
  <div class="row">
    <input-text
      v-model="filtro.CodigoVerificacao"
      class="col-12 col-md-6"
      :label="$t('modulos.verificacao_intermediaria.filtro.codigo')"
      em-filtro
    />
    <input-text
      v-model="filtro.CodigoInstrumento"
      class="col-12 col-md-6"
      :label="$t('modulos.verificacao_intermediaria.filtro.instrumento')"
      em-filtro
    />
    <input-text
      v-model="filtro.NomeTipoInstrumento"
      class="col-12 col-md-6"
      :label="$t('modulos.verificacao_intermediaria.filtro.tipo_instrumento')"
      em-filtro
    />
    <input-text
      v-model="filtro.NomeCliente"
      class="col-12 col-md-6"
      :label="$t('modulos.verificacao_intermediaria.filtro.cliente')"
      em-filtro
    />
    <input-date
      v-model="filtro.DataVerificacao"
      class="col-12 col-md-6"
      :label="$t('modulos.verificacao_intermediaria.filtro.data_verificacao')"
      nudge="-30px"
      em-filtro
    />
    <input-select
      v-model="filtro.situacao"
      :options="opcoes.situacaoVerificacao"
      class="col-12 col-md-6"
      :label="
        $t('modulos.verificacao_intermediaria.filtro.situacao_verificacao')
      "
      em-filtro
      retornar-objeto
    />
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      opcoes: {
        situacaoVerificacao: helpers.SituacaoVerificacaoEnum,
      },
    };
  },
};
</script>
