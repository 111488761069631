import i18n from '@/i18n';

export const enumOperacaoSituacaoIcons = [
  {
    value: 'AguardandoAnaliseTecnica',
    message: i18n.t('enums.EnumSituacaoOrdemServico.AguardandoAnaliseTecnica'),
    icon: 'clipboardEditOutline',
  },
  {
    value: 'AguardandoAprovacaoGarantia',
    message: i18n.t('enums.EnumSituacaoOrdemServico.AguardandoAprovacaoGarantia'),
    icon: 'clipboardTextOutline',
  },
  {
    value: 'AguardandoEnvioOrcamento',
    message: i18n.t('enums.EnumSituacaoOrdemServico.AguardandoEnvioOrcamento'),
    icon: 'fileDocumentAlertOutline',
  },

  {
    value: 'AguardandoAprovacaoOrcamento',
    message: i18n.t('enums.EnumSituacaoOrdemServico.AguardandoAprovacaoOrcamento'),
    icon: 'currencyUsd',
  },
  {
    value: 'AguardandoPeca',
    message: i18n.t('enums.EnumSituacaoOrdemServico.AguardandoPeca'),
    icon: 'alertCircleOutline',
  },
  {
    value: 'ProgramacaoTerceiroDia',
    message: i18n.t('enums.EnumSituacaoOrdemServico.ProgramacaoTerceiroDia'),
    icon: 'numeric3CircleOutline',
  },
  {
    value: 'ProgramacaoSegundoDia',
    message: i18n.t('enums.EnumSituacaoOrdemServico.ProgramacaoSegundoDia'),
    icon: 'numeric2CircleOutline',
  },
  {
    value: 'ProgramacaoDia',
    message: i18n.t('enums.EnumSituacaoOrdemServico.ProgramacaoDia'),
    icon: 'numeric1CircleOutline',
  },
  {
    value: 'AguardandoProgramacao',
    message: i18n.t('enums.EnumSituacaoOrdemServico.EmExecucaoManutencao'),
    icon: 'clockOutline',
  },
  {
    value: 'AguardandoProgramacaoManutencao',
    message: i18n.t('enums.EnumSituacaoOrdemServico.AguardandoProgramacaoManutencao'),
    icon: 'clockOutline',
  },
  {
    value: 'EmExecucaoManutencao',
    message: 'Em manutenção',
    icon: 'plusCircleOutline', //aterar
  },
  {
    value: 'AguardandoProgramacaoCalibracao',
    message: i18n.t('enums.EnumSituacaoOrdemServico.AguardandoProgramacaoCalibracao'),
    icon: 'clockOutline',
  },
  {
    value: 'ProgramacaoCalibracaoTerceiroDia',
    message: i18n.t('enums.EnumSituacaoOrdemServico.ProgramacaoCalibracaoTerceiroDia'),
    icon: 'numeric3CircleOutline',
  },
  {
    value: 'ProgramacaoCalibracaoSegundoDia',
    message: i18n.t('enums.EnumSituacaoOrdemServico.ProgramacaoCalibracaoSegundoDia'),
    icon: 'numeric2CircleOutline',
  },
  {
    value: 'ProgramacaoCalibracaoDia',
    message: i18n.t('enums.EnumSituacaoOrdemServico.ProgramacaoCalibracaoDia'),
    icon: 'numeric1CircleOutline',
  },
  {
    value: 'EmExecucaoCalibracao',
    message: i18n.t('enums.EnumSituacaoOrdemServico.EmExecucaoCalibracao'),
    icon: 'plusCircleOutline',
  },
  {
    value: 'AguardandoAprovacaoCertificado',
    message: i18n.t('enums.EnumSituacaoOrdemServico.AguardandoAprovacaoCertificado'),
    icon: 'stickerTextOutline',
  },
  {
    value: 'Calibrado',
    message: i18n.t('enums.EnumSituacaoOrdemServico.Calibrado'),
    icon: 'checkDecagramOutline',
  },
  {
    value: 'NaoCalibrado',
    message: i18n.t('enums.EnumSituacaoOrdemServico.NaoCalibrado'),
    icon: 'closeCircleOutline',
  },
  {
    value: 'Reprovado',
    message: i18n.t('enums.EnumSituacaoOrdemServico.Reprovado'),
    icon: 'currencyUsdOff',
  },
  {
    value: 'ReprovadoPeloOrcamento',
    message: 'Reprovado pelo orçamento',
    icon: 'currencyUsdOff',
  },
  {
    value: 'SemMovimento',
    message: i18n.t('enums.EnumSituacaoOrdemServico.SemMovimento'),
    icon: 'octagonOutline',
  },

  // EnumAuxiliares

  {
    value: 'EmAndamento',
    message: 'Em andamento',
    icon: 'fileDocumentEditOutline',
  },
  {
    value: 'AguardandoSuplemento',
    message: 'Aguardando suplemento',
    icon: 'mdiTimerSand',
  },
  {
    value: 'AguardandoPlanoInspecao',
    message: 'Aguardando plano de inspeção',
    icon: 'clipboardSearchOutline',
  },
  {
    value: 'AguardandoFaturamento',
    message: 'Aguardando faturamento',
    icon: 'mdiBank',
  },
  {
    value: 'AguardandoEntrega',
    message: 'Aguardando entrega',
    icon: 'mdiTruckDeliveryOutline',
  },
  {
    value: 'Concluido',
    message: 'Concluído',
    icon: 'plusCircleOutline',
  },

  // Enum para evitar quebrar situação de AguardandoProgramacao e Em Execuçao

  {
    value: 'AguardandoProgramacao',
    message: 'Aguardando programação',
    icon: 'clockOutline',
  },
  {
    value: 'EmExecucao',
    message: 'Em Execução',
    icon: 'plusCircleOutline',
  },

  // EnumSituacaoVerificacao  
  {
    value: 'NaoValidado',
    message: 'Não Validado',
    icon: 'accountClockOutline',
  },
  {
    value: 'Aprovada',
    message: 'Aprovada',
    icon: 'fileCheckOutline',
  },
  {
    value: 'Reprovada',
    message: 'Reprovada',
    icon: 'fileDocumentRemoveOutline',
  },
];
